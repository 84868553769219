class HomepageVideoHeader {
  constructor() {
    const posterImg = document.querySelector('[data-video-header-poster-img]');
    if (posterImg === undefined) { return; }

    const posterImgSrc = posterImg.getAttribute('src');
    posterImg.outerHTML = this.videoHtml(posterImgSrc);
  }

  videoHtml(posterImgSrc) {
    return `
      <video
        class="glide__video"
        poster="${posterImgSrc}"
        loop
        muted
        playsinline
        onloadeddata="window.homepageVideoHeader.videoHeaderLoaded(event)"
        onprogress="window.homepageVideoHeader.videoHeaderLoaded(event)"
      >
        <source src="/videos/roofcare-ambient_v5.webm" type="video/webm">
        <source src="/videos/roofcare-ambient_v5.mp4" type="video/mp4">
      </video>
    `;
  }

  /* eslint class-methods-use-this: "off" */
  videoHeaderLoaded(event) {
    // Only run once
    if (window.videoHeaderLoadedStatus === 'done') {
      return;
    }
    window.videoHeaderLoadedStatus = 'done';

    const video = event.target;
    const videoHeaderCaption1 = document.querySelector('[data-video-header-homepage-caption-1]');
    const videoHeaderCaption2 = document.querySelector('[data-video-header-homepage-caption-2]');
    const videoHeaderCaption3 = document.querySelector('[data-video-header-homepage-caption-3]');

    // Polyfill `requestVideoFrameCallback` API for unsupported browsers (Firefox)
    if (!('requestVideoFrameCallback' in HTMLVideoElement.prototype) && 'getVideoPlaybackQuality' in HTMLVideoElement.prototype) {
      // console.log("This browser requires the RVFC polyfill");

      HTMLVideoElement.prototype._rvfcpolyfillmap = {};
      HTMLVideoElement.prototype.requestVideoFrameCallback = function (callback) {
        const quality = this.getVideoPlaybackQuality();
        const baseline = this.mozPresentedFrames || this.mozPaintedFrames || quality.totalVideoFrames - quality.droppedVideoFrames;

        const check = (old, now) => {
          const newquality = this.getVideoPlaybackQuality();
          const presentedFrames = this.mozPresentedFrames || this.mozPaintedFrames || newquality.totalVideoFrames - newquality.droppedVideoFrames;
          if (presentedFrames > baseline) {
            const processingDuration = this.mozFrameDelay || (newquality.totalFrameDelay - quality.totalFrameDelay) || 0;
            const timediff = now - old; // HighRes diff
            callback(now, {
              presentationTime: now + processingDuration * 1000,
              expectedDisplayTime: now + timediff,
              width: this.videoWidth,
              height: this.videoHeight,
              mediaTime: Math.max(0, this.currentTime || 0) + timediff / 1000,
              presentedFrames,
              processingDuration
            })
            delete this._rvfcpolyfillmap[handle];
          } else {
            this._rvfcpolyfillmap[handle] = requestAnimationFrame(newer => check(now, newer));
          }
        }

        const handle = Date.now();
        const now = performance.now();
        this._rvfcpolyfillmap[handle] = requestAnimationFrame(newer => check(now, newer));
        return handle // spec says long, not doube, so can't re-use performance.now
      }

      HTMLVideoElement.prototype.cancelVideoFrameCallback = function (handle) {
        cancelAnimationFrame(this._rvfcpolyfillmap[handle]);
        delete this._rvfcpolyfillmap[handle];
      }
    }

    // Programmtically start the video, instead of using the `autoplay` attribute on the <video> DOM element
    video.play();

    let startTime = 0.0;
    const updateCanvas = (now, metadata) => {
      if (startTime === 0.0) {
        startTime = now;
      }

      // Output available metadata
      // console.log('metadata = ', metadata);

      // Check within time range instead of absolute time value
      // the polyfilled `metadata.mediaTime` sometimes doesnt land on a specific number
      if (metadata.mediaTime >= 0 && metadata.mediaTime <= 0.3) {
        if (videoHeaderCaption3) {
          videoHeaderCaption3.style.position = 'absolute';
        }
        videoHeaderCaption1.style.position = 'relative';
        videoHeaderCaption1.style.opacity = '1';
      }

      if (metadata.mediaTime >= 2.0 && metadata.mediaTime <= 2.3) {
        if (videoHeaderCaption3) {
          videoHeaderCaption1.style.opacity = '0';
        }
      }

      if (metadata.mediaTime >= 2.4 && metadata.mediaTime <= 2.7) {
        if (videoHeaderCaption3) {
          videoHeaderCaption1.style.position = 'absolute';
          videoHeaderCaption2.style.position = 'relative';
          videoHeaderCaption2.style.opacity = '1';
        }
      }

      if (metadata.mediaTime >= 5.1 && metadata.mediaTime <= 5.4) {
        if (videoHeaderCaption3) {
          videoHeaderCaption2.style.opacity = '0';
        }
      }

      if (metadata.mediaTime >= 5.6 && metadata.mediaTime <= 5.9) {
        if (videoHeaderCaption3) {
          videoHeaderCaption2.style.position = 'absolute';
          videoHeaderCaption3.style.position = 'relative';
          videoHeaderCaption3.style.opacity = '1';
        }
      }

      if (metadata.mediaTime >= 8.8 && metadata.mediaTime <= 9.1) {
        if (videoHeaderCaption3) {
          videoHeaderCaption3.style.opacity = '0';
        }
      }

      // Callback
      video.requestVideoFrameCallback(updateCanvas);
    };
    // Initial callback
    video.requestVideoFrameCallback(updateCanvas);
  }


}

export default HomepageVideoHeader;
